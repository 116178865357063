import React, { useState } from "react";
import { Link } from "react-router-dom";
import close_sidenav_icon from "../assets/close_sidenav_icon.png";
import side_nav_icon from "../assets/side-nav-icon.png";

const SideNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className={`sidenav ${isOpen ? "open" : ""}`}>
        <nav>
          <img
            className="side-nav-close-icon"
            src={close_sidenav_icon}
            alt="close"
            onClick={toggleNav}
          />

          <div className="sidenav-link-list">
            <Link className="side-nav-link" to="/blog">
              BLOG
            </Link>
            <Link className="side-nav-link" to="/portfolio">
              PORTFOLIO
            </Link>
            <Link className="side-nav-link" to="/about">
              ABOUT ME
            </Link>
            <Link className="side-nav-link" to="/contact">
              CONTACT ME
            </Link>
          </div>
        </nav>
      </div>

      <img
        className="side-nav-open-icon"
        src={side_nav_icon}
        alt="menu"
        onClick={toggleNav}
      />
    </>
  );
};

export default SideNav;
