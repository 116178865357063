import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import BLogPostData from "../data/BlogPostData";

export default class Blog extends Component {
  truncateContent(content, wordsLimit) {
    const words = content.split(" ");
    const truncatedContent = words.slice(0, wordsLimit).join(" ");

    if (words.length > wordsLimit) {
      return `${truncatedContent}...`;
    }

    return truncatedContent;
  }
  render() {
    return (
      <div>
        <NavBar />
        <div className="blog">
          <h1>ALL POSTS</h1>
          <div className="blog-preview">
            {BLogPostData.map((blogPost) => (
              <Link
                to={`/blog/blog-post/${blogPost.id}`}
                className="blog-link"
                key={blogPost.id}
              >
                <div className="blog-img-wrapper">
                  <img
                    src={blogPost.img_url}
                    alt={blogPost.title}
                    className="blog-img"
                  />
                </div>
                <h3 className="preview-header">{blogPost.title}</h3>
                <p className="preview-subheader">
                  {this.truncateContent(blogPost.content, 20)}
                </p>
              </Link>
            ))}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
