import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import CertificatesData from "../data/CertificatesData";

export default class Certificates extends Component {
  handleCertificateClick = (url) => {
    window.open(url, "_blank");
  };
  render() {
    return (
      <div>
        <NavBar />
        <div className="blog">
          <h1>MY CERTIFICATES</h1>

          <div className="landing-preview">
            {CertificatesData.map((certificate) => (
              <div
                key={certificate.id}
                onClick={() => this.handleCertificateClick(certificate.url)}
              >
                <img
                  src={require(`../assets/${certificate.img_url}`)}
                  alt={`${certificate.name} icon`}
                  className="landing-preview-img"
                />
                <h4>{certificate.name}</h4>
                <p>{certificate.description}</p>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
