import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import my_img from "../assets/me.jpg";

export default class About extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <div className="about">
          <h1>ABOUT ME</h1>
          <div className="about-wrapper">
            <p>
              Dedicated Information Technologist with a strong skill set
              encompassing product management, web design, development, social
              media marketing, and communication. My extensive knowledge in
              communication and information technology, coupled with a
              Bachelor's degree in Information and Communication Science and
              over three years of hands- on experience, has consistently
              contributed to the growth and advancement of the organizations
              I've had the privilege to work with.
            </p>
            <img src={my_img} alt="img icon" className="about-img-icon" />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
