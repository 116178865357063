import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LandingPage from "./pages/LandingPage";
import Blog from "./pages/Blog";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeSharedLayout from "./pages/HomeSharedLayout";
import Portfolio from "./pages/Portfolio";
import About from "./pages/About";
import Contact from "./pages/Contact";
import BlogPost from "./pages/BlogPost";
import BlogSharedLayout from "./pages/BlogSharedLayout";
import Error404 from "./pages/Error404";
import Certificates from "./pages/Certificates";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeSharedLayout />}>
          <Route index element={<LandingPage />} />
          <Route path="blog" element={<BlogSharedLayout />}>
            <Route index element={<Blog />} />
            <Route path="blog-post/:id" element={<BlogPost />} />
          </Route>
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="certificates" element={<Certificates />} />
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
      <ToastContainer theme="colored" />
    </BrowserRouter>
  );
}

export default App;
