const DesignsData = [
  {
    id: 1,
    img_url: "rivulets.jpg",
  },
  {
    id: 2,
    img_url: "food-cricle-logo.jpg",
  },
  {
    id: 3,
    img_url: "debelles-logo-mockup.jpg",
  },
  {
    id: 4,
    img_url: "debelles-flyer.png",
  },
  {
    id: 5,
    img_url: "debelles-logo.png",
  },
  {
    id: 6,
    img_url: "debelles-xmas-flyer.png",
  },
  {
    id: 7,
    img_url: "blue-toon.jpg",
  },
  {
    id: 8,
    img_url: "des-toon.jpg",
  },
  {
    id: 9,
    img_url: "kids-toon.jpg",
  },
  {
    id: 10,
    img_url: "sia-toon.jpg",
  },
  {
    id: 11,
    img_url: "tshirt-mockup1.jpg",
  },
  {
    id: 12,
    img_url: "tshirt-mockup2.jpg",
  },
  {
    id: 13,
    img_url: "tshirt-mockup3.jpg",
  },
  {
    id: 14,
    img_url: "eid.png",
  },
  {
    id: 15,
    img_url: "fin-success.png",
  },
  {
    id: 16,
    img_url: "ksf-logo.png",
  },
  {
    id: 17,
    img_url: "naija-independence.png",
  },
  {
    id: 18,
    img_url: "new-month.png",
  },
  {
    id: 19,
    img_url: "refer-win.png",
  },
  {
    id: 20,
    img_url: "matti-iv.png",
  },
];

export default DesignsData;
