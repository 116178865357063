import React, { Component } from "react";
import { toast } from "react-toastify";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

export default class Contact extends Component {
  handleSubmit = async (e) => {
    toast.info("Submitting, please wait...", { autoClose: 2000 });

    e.preventDefault();
    const { name, email, phone, message } = e.target.elements;

    // Send data to the backend
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/send-email`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name.value,
          email: email.value,
          phone: phone.value,
          message: message.value,
        }),
      }
    );

    if (!response.ok) {
      const errorMessage = await response.text();
      console.error(`Error: ${errorMessage}`);
      toast.error("Failed to send email. Please try again later.");
    }

    if (response.status === 200) {
      toast.success("Email sent successfully!");
    }
  };

  render() {
    return (
      <div>
        <NavBar />
        <div className="contact">
          <h1>CONTACT ME</h1>
          <div className="contact-wrapper">
            <form onSubmit={this.handleSubmit} className="contact-form">
              <input
                className="contact-input-box"
                type="text"
                name="name"
                placeholder="Name"
                required
              />
              <input
                className="contact-input-box"
                type="email"
                name="email"
                placeholder="Email"
                required
              />
              <input
                className="contact-input-box"
                type="tel"
                name="phone"
                placeholder="Phone"
                required
              />
              <textarea
                className="contact-input-box-message"
                name="message"
                placeholder="Message"
                required
              />
              <div className="contact-submit">
                <button className="contact-submit-button" type="submit">
                  Submit
                </button>
              </div>
            </form>
            <div className="contact-details">
              <div>
                <h3>Email</h3>
                <p>yomibamidele23@gmail.com</p>
              </div>
              <div>
                <h3>Phone no</h3>
                <p>+234 806 930 7341</p>
              </div>
              <div>
                <h3>Address</h3>
                <p>Lagos, Nigeria</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
