const CertificatesData = [
  {
    id: 1,
    name: "React Certificate",
    description:
      "Developing Front-End Apps with React, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "react-cert.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/6DPL8S2DPGUY",
  },
  {
    id: 2,
    name: "Software Product Management",
    description:
      "This achievement required mastering the knowledge and demonstrating the application of Agile practices within software processes, requirements, planning, and improvement to create better software products.",
    img_url: "spm-cert.jpg",
    url: "https://www.coursera.org/account/accomplishments/specialization/ZXN9VXFMCJ6V",
  },
  {
    id: 3,
    name: "Developing Back-End Apps with Node.js and Express",
    description:
      "Developing Back-End Apps with Node.js and Express, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "nodejs-cert.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/8EJZM3HEKTVF",
  },
  {
    id: 4,
    name: "Social Media Marketing",
    description:
      "Social Media Marketing, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "smm-cert.jpg",
    url: "https://www.coursera.org/account/accomplishments/specialization/68ARPL9MV3CX",
  },
  {
    id: 5,
    name: "Python for Data Science, Al & Development",
    description:
      "Python for Data Science, Al & Development, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "data-science-cert.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/BPQUXRXMNXJZ",
  },
  {
    id: 6,
    name: "Introduction to Web Development with HTML, CSS & JavaScript",
    description:
      "Introduction to Web Development with HTML, CSS & JavaScript, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "frontend-cert.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/SZ3T82NXB74T",
  },
  {
    id: 7,
    name: "Getting Started with Git and GitHub",
    description:
      "Getting Started with Git and GitHub, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "git-cert.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/7RWCQ5UE69SQ",
  },
  {
    id: 8,
    name: "Introduction to Cloud Computing",
    description:
      "Introduction to Cloud Computing, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "cloud-computing.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/M7XTWKEDGCAW",
  },
  {
    id: 9,
    name: "Software Product Management Capstone",
    description:
      "Software Product Management Capstone, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "spm-capstone.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/AFR3U47A62Q3",
  },
  {
    id: 10,
    name: "Reviews & Metrics for Software Improvements",
    description:
      "Reviews & Metrics for Software Improvements, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "spm-metrics.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/JF96MMXHKA49",
  },
  {
    id: 11,
    name: "Software Processes and Agile Practices",
    description:
      "Software Processes and Agile Practices, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "spm-agile.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/Z2C2AM76QM5T",
  },
  {
    id: 12,
    name: "Client Needs and Software Requirements",
    description:
      "Client Needs and Software Requirements, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "spm-needs.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/TBGU9WYJ9J69",
  },
  {
    id: 13,
    name: "Agile Planning for Software Products",
    description:
      "Agile Planning for Software Products, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "spm-planning.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/LGV85B4566X4",
  },
  {
    id: 14,
    name: "Social Marketing Capstone Project",
    description:
      "Social Marketing Capstone Project, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "smm-capstone.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/78Q7SH6MEL7A",
  },
  {
    id: 15,
    name: "The Business of Social",
    description:
      "The Business of Social, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "smm-business.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/HZCK3QQC6HKW",
  },
  {
    id: 16,
    name: "Content, Advertising & Social IMC",
    description:
      "Content, Advertising & Social IMC, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "smm-content.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/U75GRA97MRXP",
  },
  {
    id: 17,
    name: "Engagement & Nurture Marketing Strategies",
    description:
      "Engagement & Nurture Marketing Strategies, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "smm-emgagement.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/UNF5R2GV9VLD",
  },
  {
    id: 18,
    name: "The Importance of Listening",
    description:
      "The Importance of Listening , an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "smm-listening.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/ZK2ZB7R7HTBA",
  },
  {
    id: 19,
    name: "What is Social?",
    description:
      "What is Social?, an online non-credit course authorized by IBM and offered through Coursera.",
    img_url: "smm-social.jpg",
    url: "https://www.coursera.org/account/accomplishments/records/L4YEFTT9K9GN",
  },
];

export default CertificatesData;
