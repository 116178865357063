import React, { Component } from "react";
import WebApps from "../data/WebAppsData";
import Designs from "../data/DesignsData";
import Certificates from "../data/CertificatesData";
import { Link } from "react-router-dom";

export default class LandingMyWorks extends Component {
  handleWorkClick = (url) => {
    window.open(url, "_blank");
  };

  render() {
    const topWebApps = WebApps.slice(0, 3);
    const topDesigns = Designs.slice(0, 3);
    const topCertficates = Certificates.slice(0, 3);

    return (
      <div className="landing-myworks">
        <h2>MY WORKS</h2>
        <h3>Web Development</h3>
        <div className="landing-preview">
          {topWebApps.map((webApp) => (
            <div
              key={webApp.id}
              onClick={() => this.handleWorkClick(webApp.url)}
            >
              <div className="landing-preview-img-wrapper">
                <img
                  src={require(`../assets/${webApp.img_url}`)}
                  alt={`${webApp.name} icon`}
                  className="landing-preview-img"
                />
              </div>
              <h4 className="preview-header">{webApp.name}</h4>
              <p className="preview-subheader">{webApp.description}</p>
            </div>
          ))}
        </div>
        <Link to="/portfolio" className="see-all">
          See all
        </Link>
        <h3>Designs</h3>
        <div className="blog-preview">
          {topDesigns.map((design) => (
            <div
              key={design.id}
              onClick={() => this.handleWorkClick(design.url)}
            >
              <div className="landing-img-wrapper">
                <img
                  src={require(`../assets/${design.img_url}`)}
                  alt={`${design.name} icon`}
                  className="blog-img"
                />
              </div>
              <h4 className="preview-header">{design.name}</h4>
              <p className="preview-subheader">{design.description}</p>
            </div>
          ))}
        </div>
        <Link to="/portfolio" className="see-all">
          See all
        </Link>
        <h3>MY CERTIFICATES</h3>
        <div className="landing-preview">
          {topCertficates.map((certificate) => (
            <div
              key={certificate.id}
              onClick={() => this.handleWorkClick(certificate.url)}
            >
              <div className="blog-img-wrapper">
                <img
                  src={require(`../assets/${certificate.img_url}`)}
                  alt={`${certificate.name} icon`}
                  className="blog-img"
                />
              </div>
              <h4 className="preview-header">{certificate.name}</h4>
              <p className="preview-subheader">{certificate.description}</p>
            </div>
          ))}
        </div>
        <Link to="/certificates" className="see-all">
          See all
        </Link>
        <h3>MY SOFT SKILLS</h3>
        <div className="soft-skills">
          <ul>
            <li>Communication</li>
            <li>Problem Solving</li>
            <li>Adaptability</li>
            <li>Collaboration</li>
            <li>Time Management</li>
            <li>Leadership</li>
            <li>Attention to Detail</li>
            <li>Continuous Learning</li>
            <li>Creativity</li>
          </ul>
        </div>
      </div>
    );
  }
}
