import React, { useState } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import dropdownOpenIcon from "../assets/dropdown-open.png";
import dropdownClosedIcon from "../assets/dropdown-closed.png";
import WebApps from "../data/WebAppsData";
import Designs from "../data/DesignsData";
import UiData from "../data/UiData";

export const options = [
  "Web development",
  "UI Design",
  "Graphic Design",
  // "Social Marketing",
  // "Multimedia",
];

export default function Portfolio() {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleWorkClick = (url) => {
    window.open(url, "_blank");
  };

  const handleDesignClick = (event) => {
    const clickedImageSrc = event.target.src;
    window.open(clickedImageSrc, "_blank");
  };

  return (
    <div>
      <NavBar />
      <div className="blog">
        <h1>MY PORTFOLIO</h1>
        <div className="portfolio-dropdown">
          <div className={`dropdown ${isOpen ? "open" : ""}`}>
            <div className="selected-option" onClick={toggleDropdown}>
              {selectedOption}
              <img
                src={isOpen ? dropdownOpenIcon : dropdownClosedIcon}
                alt="Dropdown Icon"
                className="dropdown-icon"
              />
            </div>
            {isOpen && (
              <ul className="options-list">
                {options.map((option) => (
                  <li key={option} onClick={() => handleOptionSelect(option)}>
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {selectedOption === "Web development" && (
          <div className="landing-preview">
            {WebApps.map((webApp) => (
              <div key={webApp.id} onClick={() => handleWorkClick(webApp.url)}>
                <img
                  src={require(`../assets/${webApp.img_url}`)}
                  alt={`${webApp.name} icon`}
                  className="landing-preview-img"
                />
                <h4 className="preview-header">{webApp.name}</h4>
                <p className="preview-subheader">{webApp.description}</p>
              </div>
            ))}
          </div>
        )}
        {selectedOption === "Product Management" && (
          <div className="landing-preview">
            {WebApps.map((product) => (
              <div
                key={product.id}
                onClick={() => handleWorkClick(product.url)}
              >
                <img
                  src={require(`../assets/${product.img_url}`)}
                  alt={`${product.name} icon`}
                  className="landing-preview-img"
                />
                <h4 className="preview-header">{product.name}</h4>
                <p className="preview-subheader">{product.description}</p>
              </div>
            ))}
          </div>
        )}
        {selectedOption === "UI Design" && (
          <div className="design-preview" onClick={handleDesignClick}>
            {UiData.map((design) => (
              <div key={design.id} className="design-img-wrapper">
                <img
                  src={require(`../assets/${design.img_url}`)}
                  alt={`${design.name} icon`}
                  className="design-img"
                />
              </div>
            ))}
          </div>
        )}
        {selectedOption === "Graphic Design" && (
          <div className="design-preview" onClick={handleDesignClick}>
            {Designs.map((design) => (
              <div key={design.id} className="design-img-wrapper">
                <img
                  src={require(`../assets/${design.img_url}`)}
                  alt={`${design.name} icon`}
                  className="design-img"
                />
              </div>
            ))}
          </div>
        )}

        {selectedOption === "Multimedia" && (
          <div className="landing-preview">
            {WebApps.map((media) => (
              <div key={media.id} onClick={() => handleWorkClick(media.url)}>
                <img
                  src={require(`../assets/${media.img_url}`)}
                  alt={`${media.name} icon`}
                  className="landing-preview-img"
                />
                <h4 className="preview-header">{media.name}</h4>
                <p className="preview-subheader">{media.description}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}
