import React from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import BLogPostData from "../data/BlogPostData";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const BlogPost = () => {
  const { id } = useParams();
  const blogPost = BLogPostData.find((post) => post.id.toString() === id);

  if (!blogPost) {
    return <div>Blog post not found</div>;
  }

  return (
    <div>
      <NavBar />
      <div className="blog-post">
        <h1>{blogPost.title}</h1>
        <div className="blog-post-wrapper">
          <div className="blog-post-img-wrapper">
            <img
              src={blogPost.img_url}
              alt={blogPost.title}
              className="blog-img"
            />
            <p>
              Image by{" "}
              <a
                href={blogPost.img_author_url}
                target="blank"
                rel="noopener noreferrer"
              >
                {blogPost.img_author}
              </a>
            </p>
          </div>

          <ReactMarkdown>
            {/* <pre>{blogPost.content}</pre> */}
            {blogPost.content}
          </ReactMarkdown>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogPost;
