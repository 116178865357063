import React, { Component } from "react";
import site_logo from "../assets/site-logo.png";
import { Link } from "react-router-dom";
import SideNav from "./SideNav";

export default class NavBar extends Component {
  render() {
    return (
      <div className="navbar">
        <Link to="/">
          <img src={site_logo} alt="site logo" className="site-logo" />
        </Link>

        <div className="nav-menu">
          <Link to="/blog" className="nav-menu-link">
            BLOG
          </Link>
          <Link to="/portfolio" className="nav-menu-link">
            MY PORTFOLIO
          </Link>
          <Link to="/about" className="nav-menu-link">
            ABOUT ME
          </Link>
        </div>
        <Link to="/contact" className="nav-menu-link">
          <div className="contact-button">CONTACT ME</div>
        </Link>
        <SideNav />
      </div>
    );
  }
}
