const UiData = [
  {
    id: 1,
    img_url: "fash-desk.jpg",
  },
  {
    id: 2,
    img_url: "investor-lap.png",
  },
  {
    id: 3,
    img_url: "fash-lap.jpg",
  },
  {
    id: 4,
    img_url: "cv-pad.png",
  },
  {
    id: 5,
    img_url: "fash-tab.jpg",
  },
  {
    id: 6,
    img_url: "cv-tab.png",
  },
  {
    id: 7,
    img_url: "bust-phone.png",
  },
  {
    id: 8,
    img_url: "studyplan-landing.png",
  },
  {
    id: 9,
    img_url: "debelles-landing.png",
  },
  {
    id: 10,
    img_url: "yomi-landing.png",
  },
  {
    id: 11,
    img_url: "bust-landing.png",
  },
  {
    id: 12,
    img_url: "recall-mobile.png",
  },
  {
    id: 13,
    img_url: "yomi-mobile.png",
  },
  {
    id: 14,
    img_url: "studyplan-mobile.png",
  },
  {
    id: 15,
    img_url: "noval-landing.png",
  },
  {
    id: 16,
    img_url: "noval-mobile.png",
  },
  {
    id: 17,
    img_url: "trndsta-landing.png",
  },
];

export default UiData;
