import React, { Component } from "react";
import site_logo from "../assets/site-logo.png";
import twitter from "../assets/Twitter.png";
import github from "../assets/GitHub.png";
import linkedin from "../assets/Linkedin.png";
import behance from "../assets/Behance.png";
import instagram from "../assets/Instagram.png";
import whatsapp from "../assets/WhatsApp.png";
import { Link } from "react-router-dom";
import resumePDF from "../assets/oluwayomi_resume.pdf";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <Link to="/">
          <img src={site_logo} alt="site logo" className="site-logo" />
        </Link>
        <div className="footer-links-wrapper">
          <div className="footer-links">
            <Link to="/blog" className="nav-menu-link">
              Blog
            </Link>
            <Link to="/about" className="nav-menu-link">
              About Me
            </Link>
            <Link to="/contact" className="nav-menu-link">
              Contact Me
            </Link>
          </div>
          <div className="footer-links">
            <Link to="/portfolio" className="nav-menu-link">
              Portfolio
            </Link>
            <Link to="/certificates" className="nav-menu-link">
              Certificates
            </Link>
            <a
              href={resumePDF}
              target="_blank"
              rel="noopener noreferrer"
              // download="Resume.pdf"
              className="resume-link"
            >
              Resume
            </a>
          </div>
        </div>

        <div className="get-in-touch">
          <h4>Get in Touch</h4>
          <div className="footer-socials">
            <a
              href="https://twitter.com/manlikeyomi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={twitter}
                alt="twitter social"
                className="footer-social-icon"
              />
            </a>
            <a
              href="https://github.com/yomibam"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={github}
                alt="github social"
                className="footer-social-icon"
              />
            </a>
            <a
              href="https://linkedin.com/in/yomi-bamidele/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedin}
                alt="linkedin social"
                className="footer-social-icon"
              />
            </a>
            <a
              href="https://behance.net/yomibamidele"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={behance}
                alt="behance social"
                className="footer-social-icon"
              />
            </a>
            <a
              href="https://instagram.com/trndsta/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={instagram}
                alt="instagram social"
                className="footer-social-icon"
              />
            </a>
            <a
              href="https://wa.me/2348069307341/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={whatsapp}
                alt="whatsapp social"
                className="footer-whatsapp-icon"
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
