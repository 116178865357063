import React, { Component } from "react";
import NavBar from "../components/NavBar";
import LandingHero from "../components/LandingHero";
import TechStack from "../components/TechStack";
import LandingMyWorks from "../components/LandingMyWorks";
import Footer from "../components/Footer";

export default class LandingPage extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <LandingHero />
        <TechStack />
        <LandingMyWorks />
        <Footer />
      </div>
    );
  }
}
