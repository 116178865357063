const WebAppsData = [
  {
    id: 1,
    name: "Noval website",
    description: "A full website developed for a software company.",
    img_url: "noval-logo.png",
    url: "https://novalcorp.onrender.com",
  },
  {
    id: 2,
    name: "Study Plan",
    description:
      "Study plan is a website that simplifies user's educational journey, connecting users with the perfect courses across the globe.",
    img_url: "study-plan-img.png",
    url: "https://studyplan-85j9.onrender.com/",
  },
  {
    id: 3,
    name: "Debelle's Landing Page",
    description:
      "A landing page for debelles hairs, developed with React and Tailwind. It links the landing page to the Business' Whatsapp using the Whatsapp api.",
    img_url: "debelles-logo-white.png",
    url: "https://debelles.onrender.com/",
  },
  {
    id: 4,
    name: "Portfolio website",
    description:
      "This is my portfolio website that showcases all my work and skills. It was developed using React.js on the front-end, Node/Express.js on the back-end, and MongoDB for the database.",
    img_url: "portfolio-img.png",
    url: "https://yomiportfolio.com.ng/",
  },
  {
    id: 5,
    name: "Recall App (in progress)",
    description:
      "Recall app is an AI reminder app will allow users to set context-aware reminders using natural language input, combining time and location triggers.",
    img_url: "recall-img.png",
    url: "https://recall-app.onrender.com/",
  },
  {
    id: 6,
    name: "Tubams Enterprise",
    description:
      "A landing page for Tubams Enterprise, developed with react and tailwind.",
    img_url: "tubams-logo.png",
    url: "https://tubams.onrender.com/",
  },
  {
    id: 7,
    name: "Trndsta Studio",
    description:
      "A website for Trndsta Studio, developed with react and tailwind.",
    img_url: "trndsta-logo.png",
    url: "https://photoport.onrender.com/",
  },
];

export default WebAppsData;
