import React, { Component } from "react";
import react_icon from "../assets//react.png";
import css from "../assets//css.png";
import html from "../assets//html.png";
import js from "../assets//js.png";
import db from "../assets//db.png";
import figma from "../assets//figma.png";
import ai from "../assets//ai.png";
import ps from "../assets//ps.png";
import canva from "../assets//canva.png";
import pr from "../assets/pr.png";
import tw from "../assets/tw.png";
import node from "../assets/node.png";

export default class TechStack extends Component {
  render() {
    return (
      <div className="tech-stack">
        <img src={react_icon} alt="react icon" className="tech-stack-icon" />
        <img src={css} alt="react icon" className="tech-stack-icon" />
        <img src={tw} alt="tailwind icon" className="tech-stack-icon" />
        <img src={html} alt="html icon" className="tech-stack-icon" />
        <img src={js} alt="js icon" className="tech-stack-icon" />
        <img src={db} alt="mongodb icon" className="mongo-figma-icon" />
        <img src={node} alt="node icon" className="tech-stack-icon" />
        <img src={figma} alt="figma icon" className="mongo-figma-icon" />
        <img src={ai} alt="illustrator icon" className="tech-stack-icon" />
        <img src={ps} alt="photoshop icon" className="tech-stack-icon" />
        <img src={pr} alt="premier icon" className="tech-stack-icon" />
        <img src={canva} alt="canva icon" className="tech-stack-icon" />
      </div>
    );
  }
}
