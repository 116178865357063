import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import resumePDF from "../assets/oluwayomi_resume.pdf";

const LandingHero = () => {
  const [dynamicText, setDynamicText] = useState("");
  const wordIndexRef = useRef(0);
  const charIndexRef = useRef(0);
  const isDeletingRef = useRef(false);

  useEffect(() => {
    // Words to be displayed in the typing effect
    const words = ["Oluwayomi", "Bamidele"];

    // Function to simulate typing effect
    const typeEffect = () => {
      const currentWord = words[wordIndexRef.current];
      const currentChar = currentWord.substring(0, charIndexRef.current);
      setDynamicText(currentChar);

      if (!isDeletingRef.current && charIndexRef.current < currentWord.length) {
        // If typing, increment charIndex to display the next character
        charIndexRef.current++;
      } else if (isDeletingRef.current && charIndexRef.current > 0) {
        // If deleting, decrement charIndex to remove the previous character
        charIndexRef.current--;
      } else {
        // If word is fully typed or deleted, switch to the next word
        isDeletingRef.current = !isDeletingRef.current;
        wordIndexRef.current = !isDeletingRef.current
          ? (wordIndexRef.current + 1) % words.length
          : wordIndexRef.current;
      }

      // Set the interval for the next iteration of the typing effect
      const typeEffectInterval = isDeletingRef.current ? 100 : 400;
      setTimeout(typeEffect, typeEffectInterval);
    };

    // Initiate the typing effect when the component mounts
    typeEffect();
  }, []); // Empty dependency array ensures that this effect runs once after the initial render

  return (
    <div className="landing-hero">
      <h1 className="landing-hero-header">
        Hi, <br /> I'm{" "}
        <span
          className={`name-color ${
            isDeletingRef.current ? "stop-blinking" : ""
          }`}
        >
          {dynamicText}
        </span>
        <br /> I bring ideas to life!
      </h1>
      <h2 className="landing-hero-subheader">
        Web development, Product Management, Design, Social Marketing,
        Multimedia.
      </h2>
      <div className="contact-resume">
        <Link to="/contact" className="nav-menu-link">
          <div className="hero-button">CONTACT ME</div>
        </Link>

        <a
          href={resumePDF}
          target="_blank"
          rel="noopener noreferrer"
          // download="Resume.pdf"
          className="resume-link"
        >
          My Resume
        </a>
      </div>
    </div>
  );
};

export default LandingHero;
